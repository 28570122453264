@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: MediumBlue;
  text-decoration: underline;
  background-color: Gainsboro;
  border-radius: 8px;
}
